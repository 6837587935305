.verses {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1000px;
  padding: 15px;
  margin: 0 auto;

  &:empty {
    &::before {
      content: '';
      position: absolute;
      top: 50vh;
      left: 50vw;
      width: 30px;
      height: 30px;
      border: 3px solid var(--white);
      border-top-color: transparent;
      border-radius: 50%;
      animation: spinner 1s infinite linear;
    }
  }
}

@keyframes spinner {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
