.button {
  background-color: var(--navy);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  opacity: 0;
  pointer-events: none;
  transition: all .2s ease;

  &__close {
    width: 20px;
  }

  &:hover, &:focus-visible {
    background-color: var(--blue);
  }

  &.active {
    opacity: 1;
    pointer-events: initial;
  }

  &.disabled {
    pointer-events: none;
  }
}
