.option {
  background-color: var(--white);
  padding: 10px;
  color: var(--navy);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:hover, &:focus-visible {
    background-color: var(--blue);
    color: var(--white);
  }
}
