.form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;

  &.hidden {
    display: none;
  }

  &__input {
    position: relative;
    background-color: var(--white);
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 10px;
    color: var(--navy);
    text-align: center;
    transition: all .2s ease;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background: no-repeat center / 15px url(../icons/close.svg);
      display: block;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    &:hover, &:focus-visible {
      background-color: var(--blue);
      color: var(--white);
    }

    &::selection {
      background-color: var(--white);
      color: var(--navy);
    }
  }
}
