.verse {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;

  &::before {
    content: attr(data-id);
    opacity: 0.5;
    min-width: 30px;
  }

  &__text {
    flex-grow: 1;
    width: calc(100% - 40px);
  }

  &.active {
    background-color: var(--blue);

    + .active {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-50%);
        background-color: var(--blue);
        display: block;
        width: 100%;
        height: 20px;
      }
    }
  }
}
