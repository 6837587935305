.select {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  -webkit-user-select: none;
  user-select: none;

  &.hidden {
    display: none;
  }

  &:nth-child(odd) {
    width: 25%;
  }

  &:nth-child(even) {
    width: 50%;
  }

  &__value {
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-right: 0;
    border-radius: 10px;
    color: var(--navy);
    transition: all .2s ease;

    &:hover, &:focus-visible {
      background-color: var(--blue);
      color: var(--white);
    }

    &.active {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.disabled {
      pointer-events: none;
    }
  }

  &__text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__icon {
    flex-shrink: 0;
    width: 30px;
  }
}
