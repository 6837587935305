.options {
  position: absolute;
  top: 100%;
  left: 0;
  display: grid;
  width: 100%;
  max-height: calc(100vh - 160px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  opacity: 0;
  pointer-events: none;
  transition: all .2s ease;

  &.active {
    opacity: 1;
    pointer-events: initial;
  }
}
