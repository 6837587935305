:root {
  --white: #f8f8f9;
  --navy: #0f1495;
  --ocean: #4247c8;
  --blue: #4882da;

  --sans-serif: 'Poppins', sans-serif;
}

*, *::before, *::after {
  box-sizing: border-box;
}

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  background-color: var(--ocean);
  margin: 0;
  color: var(--white);
  font-family: var(--sans-serif);
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: none;

  &:has(.select.active) {
    overflow: hidden;
  }
}

svg {
  fill: currentColor;
  width: 25px;
}

button {
  all: unset;
  cursor: pointer;
}

input {
  all: unset;
  cursor: initial;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
