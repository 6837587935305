.controls {
  background-color: var(--navy);
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  gap: 15px;
  width: 100%;
  padding: 15px;
  font-size: 20px;
}
